<template>
  <div class="container my-5">
    <div class="d-flex align-items-center mb-4">
      <h4 class="mr-auto">Detail Tiket</h4>
      <nav>
        <ol class="breadcrumb p-0 bg-transparent">
          <li class="breadcrumb-item p-0 m-0">
            <router-link to="/user/pembinaan" class="text-muted">
              pembinaan
            </router-link>
          </li>
          <li class="breadcrumb-item active text-dark" aria-current="page">
            Detail Tiket
          </li>
        </ol>
      </nav>
    </div>
    <div class="row justify-content-center">
      <div v-if="notFound" class="col-lg-7">
        <h4 class="text-center">Tiket tidak ditemukan</h4>
      </div>
      <div v-else class="col-lg-7">
        <div
          class="card p-4 border-0 shadow-sm position-relative"
          style="background-color: white;"
          v-if="ticket.status == 'approved' || ticket.status == 'attended'"
        >
          <div class="bg-primary-50 p-3 text-center">
            <h5 class="w-75 mx-auto mb-0">{{ ticket.training_event.title }}</h5>
          </div>
          <div class="mt-4 mb-2">
            <strong>Detail Peserta : </strong>
          </div>
          <div class="row">
            <div class="col-lg-6 mb-3">
              <div class="text-muted">Nama Lengkap</div>
              <div>{{ ticket.name }}</div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="text-muted">Email</div>
              <div>{{ ticket.email }}</div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="text-muted">No Whatsapp</div>
              <div>{{ ticket.phone }}</div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="text-muted">Alamat</div>
              <div>{{ ticket.address }}</div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="text-muted mb-2">Status Pendaftaran</div>
              <div>
                <small
                  v-if="ticket.status == 'approved'"
                  class="bg-success-50 text-success py-2 px-3 rounded-pill"
                >
                  Pendaftaran Diterima
                </small>
                <small
                  v-if="ticket.status == 'attended'"
                  class="bg-success-50 text-success py-2 px-3 rounded-pill"
                >
                  Telah Mengikuti Acara
                </small>
                <small
                  v-if="ticket.status == 'pending'"
                  class="bg-success-50 text-success py-2 px-3 rounded-pill"
                >
                  Menunggu Konfirmasi
                </small>
                <small
                  v-if="ticket.status == 'canceled'"
                  class="bg-success-50 text-success py-2 px-3 rounded-pill"
                >
                  Pendaftaran Ditolak
                </small>
              </div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="text-muted mb-2">Sertifikat</div>
              <div>
                <router-link
                  :to="'/sertifikat/' + ticket.ticketcode"
                  class="text-primary"
                >
                  <a><i class="fa fa-lg fa-medal"></i> Lihat Sertifikat</a>
                </router-link>
              </div>
            </div>
          </div>

          <div class="mt-4 mb-2">
            <strong>Detail Tiket : </strong>
          </div>
          <div class="row">
            <div class="col-lg-6 mb-3">
              <div class="text-muted">ID Tiket</div>
              <div>{{ ticket.ticketcode }}</div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="text-muted">Tanggal Daftar</div>
              <div>{{ registeredDate }}</div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="text-muted">Waktu Pelaksanaan</div>
              <div>{{ convertStartDate }} - {{ convertEndDate }}</div>
              <div>{{ trainingTime }}</div>
            </div>
            <div class="col-lg-6 mb-3">
              <div class="text-muted mb-2">Tempat Pelaksanaan</div>
              <div>
                <a :href="ticket.training_event.zoom_link">
                  <i class="fa fa-lg fa-video"></i> Zoom Link
                </a>
              </div>
            </div>
          </div>
          <div class="row align-items-center mt-4">
            <div class="col-1 p-0">
              <!-- <div
                class="bg-grey-100"
                style="width: 40px;
                height: 30px;
                border-radius: 10rem 10rem 0 0;
                rotate: 90deg;"
              ></div> -->
            </div>
            <div class="col-10 p-0">
              <div style="border: 1px dashed grey;"></div>
            </div>
            <div class="col-1 p-0"></div>
          </div>
          <div class="mt-4 text-center">
            <img :src="qrCode" alt="Kode QR" class="mb-3" />
            <h5 class="text-muted">
              Scan QR diatas untuk melihat detail tiket
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "../../store";
import moment from "moment";
moment.locale("id");

export default {
  data() {
    return {
      ticket: [],
      notFound: false,
    };
  },
  computed: {
    convertStartDate: function() {
      return moment(this.ticket.training_event.start).format("LL");
    },
    convertEndDate: function() {
      return moment(this.ticket.training_event.end).format("LL");
    },
    registeredDate: function() {
      return moment(this.ticket.training_event.start).format("LL");
    },
    trainingTime() {
      return `${moment(this.ticket.training_event.start)
        .format("LT")
        .replace(".", ":")} - ${moment(this.ticket.training_event.end)
        .format("LT")
        .replace(".", ":")}`;
    },
    qrCode: function() {
      let link = `https://app.buku.kemdikbud.go.id/ticket/${this.ticket.ticketcode}`;
      return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${link}`;
    },
  },
  created() {
    axios
      .post(`${BASE_URL}api/training/ticket/${this.$route.params.id}`)
      .then((res) => {
        if (res.data.status == "failed") {
          this.notFound = true;
        } else {
          console.log(res);
          this.ticket = res.data.data;
        }
      })
      .catch((err) => console.log(err));
  },
};
</script>

<style></style>
